import { SocialBar } from '../SocialBar'
import './index.css'

const MyName: React.FC = () => (
    <h1 className="MyName">Alan J. Fitzpatrick</h1>
)

const TagLine: React.FC = () => (
    <h2 className="Tagline">
        Software Engineer who <i className="fa-solid fa-heart" style={{ color: '#9b111e' }}></i>'s Node.js
    </h2>
)

export const Splash: React.FC = () => {
    const aboutText = [
        'Full-Time Full Stack Developer, climbing the Developer Tree of Knowledge. Currently focused on React, Node.js and Kotlin.',
        'I have been fortunate enough to work with some of the biggest companies in the world, some of the oldest companies in the world, architecting projects, building CLI Tooling and more.',

        'I felt this was better than making a real site. Hey I just wanna Keep It Simple Stupid, why not try and find me on one of these'
    ]

    return (
        <div className="Splash">
            <MyName />
            <TagLine />
            <article>
                {aboutText.map((para, i) => (
                    <p className="Splash-about-p" key={i}>{para}</p>
                ))}
            </article>
            <SocialBar />
        </div>
    )
}
